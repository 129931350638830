import * as imageConversion from "image-conversion";
//压缩质量
export function getCompressionQuality(isLtSize, imgType) {
  if (isLtSize < 3) {
    return 0.93;
  } else if (isLtSize >= 2 && isLtSize < 5) {
    return 0.9;
  } else if (isLtSize >= 5 && isLtSize < 10) {
    return 0.8;
  } else if (isLtSize >= 10 && isLtSize < 20) {
    return 0.7;
  } else if (isLtSize >= 20 && isLtSize < 30) {
    return 0.6;
  }
  return 0.9;
}

// 压缩图片到指定大小（2MB）内
export async function compressImageToMaxSize(file, maxSize = 2 * 1024 * 1024) {
  let compressedFile = file;
  while (compressedFile.size > maxSize) {
    const quality = getCompressionQuality(
      compressedFile.size / 1024 / 1024,
      file.type
    );
    compressedFile = await compressImage(compressedFile, quality);
  }
  return compressedFile;
}

//压缩逻辑
export function compressImage(file, quality) {
  return new Promise((resolve, reject) => {
    imageConversion
      .compress(file, quality)
      .then((res) => {
        resolve(res);
        // console.log("压缩后体积", res.size / (1024 * 1024));
      })
      .catch((error) => {
        reject(error);
      });
  });
}
