let OSS = require("ali-oss");
export function client() {
  let client = new OSS({
    region: "oss-cn-chengdu",
    accessKeyId: "LTAI5tFpGkvhNcwn6Kaw5JTM",
    accessKeySecret: "3UgT2VGmuYVBlsCCejbVloRPNnQ2a6",
    bucket: "yd-hospital",
  });
  return client;
}

// 上传
export async function upload (filePath, file) {
    try {
      let result = await client().put(filePath, file)
      return result
    } catch (err) {
      console.log(err)
    }
  }
  
  // 删除
  export async function remove (filePath) {
    try {
      let result = await client().delete(filePath)
      return result
    } catch (err) {
      console.log(err)
    }
  }
