// axios 二次封装
import axios from "axios"
// @ts-ignore
import msg from '@/assets/js/message'
// 引入用户相关的仓库
import store from "@/store"
import router from "@/router"
const BASEURL = process.env.VUE_APP_BASE_URL

// 第一步利用axios对象对create方法，去创建axios实例（其它配置：基础路径、超时的时间）
const request = axios.create({
  // 基础路径
  // @ts-ignore
  baseURL: BASEURL, //基础路径上会携带/api
  timeout: 5000, //超时时间的设置
})
// 第二步：request实例添加请求与响应拦截器
request.interceptors.request.use((config) => {
  // 获取用户相关的小仓库，获取仓库的token，登录成功以后携带给服务器
  const token = store.state.user.token
  // config.headers['Content-Type'] = 'application/json'
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // config配置对象，headers属性请求头，经常给服务器端携带公共参数
  // 返回配置对象
  return config
})

// 第三步：响应拦截器
request.interceptors.response.use(
  (response) => {
    // 成功的回调
    // 简化数据
    return response.data
  },
  (error) => {
    console.log(error,'error');
    const { response } = error
    if (response.status === 403) {
      store.dispatch('user/userLogout')
      // 返回登录页
      router.push('/login')
    }
    const message = error.response.data.msg || error.response.data.message
    msg.errorMsg(message)
    return Promise.reject(error.response.data)
  }
)

export default request
